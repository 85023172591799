import * as Firebase from "./firebase";
import * as Products from "./products";
import * as Imgproxy from "./imgproxy";
import * as Contacts from "./contacts";
import * as Reports from "./reports";
import * as Geocode from "./geocode";

const getAuthToken = () => (localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null);

export { Firebase, Products, getAuthToken, Imgproxy, Contacts, Reports, Geocode };
